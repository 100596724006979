import { Column } from "components/common/Div";
import { Header } from "components/common/Header";
import styled from "styled-components";
import Typo from "styles/Typo";

export const TermOfPrivacyPolicy = () => {
    return (
        <Column>
            <Header type={"sub"} />
            <Container>
                <Typo.b1>개인 정보 처리 방침</Typo.b1>
                <hr />
                <Typo.b3>제 1장 총칙</Typo.b3>
                <Typo.s4>
                    FROM.ANON(이하 회사)는 정보주체의 자유와 권리 보호를 위해
                    「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,
                    적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
                    이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보
                    처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을
                    신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                    개인정보 처리방침을 수립·공개합니다.
                </Typo.s4>
                <br></br>

                <Typo.s4>
                    <Typo.s1>제 1조 (목적)</Typo.s1>
                    <br />
                    회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                    있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
                    이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에
                    따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                    <br />
                    1. 회원 가입 및 관리
                    <br />
                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                    식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세
                    미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인,
                    각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                    <br />
                    2. 재화 또는 서비스 제공
                    <br />
                    물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공,
                    맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산,
                    채권추심으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    <Typo.s1>제 2조 (처리 및 보유기간)</Typo.s1>
                    <br />① 회사는 법령에 따른 개인정보 보유·이용기간 또는
                    정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보
                    보유·이용기간 내에서 개인정보를 처리· 보유합니다.
                    <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                    <br />
                    1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴
                    시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료
                    시까지
                    <br />
                    &nbsp;&nbsp;a. 관계 법령 위반에 따른 수사·조사 등이 진행
                    중인 경우에는 해당 수사·조사 종료 시까지
                    <br />
                    &nbsp;&nbsp;b. 홈페이지 이용에 따른 채권·채무관계 잔존
                    시에는 해당 채권·채무관계 정산 시까지
                    <br />
                    2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및
                    요금결제·정산 완료시까지 다만, 다음의 사유에 해당하는
                    경우에는 해당 기간 종료 시까지
                    <br />
                    &nbsp;&nbsp;a. 「전자상거래 등에서의 소비자 보호에 관한
                    법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한
                    기록
                    <br />
                    &nbsp;&nbsp; &nbsp;&nbsp;⦁ 표시·광고에 관한 기록 : 6개월
                    <br />
                    &nbsp;&nbsp; &nbsp;&nbsp;⦁ 계약 또는 청약철회, 대금결제,
                    재화 등의 공급기록 : 5년
                    <br />
                    &nbsp;&nbsp; &nbsp;&nbsp;⦁ 소비자 불만 또는 분쟁처리에 관한
                    기록 : 3년
                    <br />
                    &nbsp;&nbsp;b. 「통신비밀보호법」에 따른 통신사실확인자료
                    보관
                    <br />
                    &nbsp;&nbsp; &nbsp;&nbsp;⦁ 가입자 전기통신일시,
                    개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국
                    위치추적자료 : 1년
                    <br />
                    &nbsp;&nbsp; &nbsp;&nbsp;⦁ 컴퓨터통신, 인터넷 로그기록자료,
                    접속지 추적자료 : 3개월
                    <br />
                    <br />
                    <Typo.s1>제 3조 (처리하는 개인정보 항목)</Typo.s1>
                    <br />
                    회사는 다음의 개인정보 항목을 처리하고 있습니다.
                    <br />
                    1. 회원 가입 및 관리
                    <br />• 필수항목 : Instagram 계정 이름
                    <br />
                    2. 재화 또는 서비스 제공
                    <br />• 필수항목 : 성명, 생년월일, 주소, 전화번호,
                    이메일주소, 신용카드번호, 은행 계좌정보
                    <br />
                    <br />
                    <Typo.s1>제 4조 (개인정보 처리의 위탁)</Typo.s1>
                    <br />① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이
                    개인정보 처리업무를 위탁하고 있습니다.
                    <br />
                    <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                    }}
                                >
                                    위탁받는 자(수탁자)
                                </th>
                                <th
                                    style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                    }}
                                >
                                    위탁업무
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                    }}
                                >
                                    Amazon web Services, Inc.
                                </td>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                    }}
                                >
                                    • AWS를 통한 서비스 운영 환경 제공 <br />•
                                    데이터 보관
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    ② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라
                    위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적
                    보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등
                    책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
                    개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                    <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는
                    지체없이 본 개인정보 처리방침을 통하여 공개하도록
                    하겠습니다.
                    <br />
                    <br />
                    <Typo.s1>제 5조 (개인정보의 국외 이전)</Typo.s1>
                    <br />
                    회사는 아래와 같이 국외에 개인 정보를 제공하고 있습니다.
                    <br />
                    1. 수탁업체: Amazon web Services, Inc.
                    <br />
                    2. 수탁업체의 위치: 410 Terry Ave N, Seattle, WA 98109, USA
                    <br />
                    3. 위탁 일시 및 방법: 서비스 이용 시점에 서버를 통해 전송
                    <br />
                    4. 정보관리책임자의 연락처 : resolution@amazon.com
                    <br />
                    5. 이전 항목 : Instagram 계정 이름
                    <br />
                    6. 개인정보의 보유 및 이용기간 : 개인 정보 삭제 전까지 보유
                    <br />
                    <br />
                    <Typo.s1>제 6조 (개인정보의 파기 절차 및 방법)</Typo.s1>
                    <br />① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등
                    개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
                    파기합니다.
                    <br />② 정보주체로부터 동의받은 개인정보 보유기간이
                    경과하거나 처리목적이 달성되었음 에도 불구하고 다른 법령에
                    따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
                    개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
                    달리하여 보존합니다.
                    <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <br />
                    1. 파기절차
                    <br />
                    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                    개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                    <br />
                    2. 파기방법
                    <br />
                    회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을
                    재생할 수 없도록 파기하며, 종이 문서에 기록·저장된
                    개인정보는 분쇄기로 분쇄 하거나 소각하여 파기합니다.
                    <br />
                    <br />
                    <Typo.s1>
                        제 7조 (정보주체와 법정대리인의 권리·의무 및 행사방법)
                    </Typo.s1>
                    <br />① 정보주체는 회사에 대해 언제든지 개인정보
                    열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                    <br />※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는
                    법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인
                    정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이
                    권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도
                    있습니다.
                    <br />② 권리 행사는회사에 대해 「개인정보 보호법」 시행령
                    제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
                    통하여 하실 수 있으며, 회사는 이에 대해 지체없이
                    조치하겠습니다.
                    <br />③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
                    등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보
                    처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른
                    위임장을 제출하셔야 합니다.
                    <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」
                    제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한
                    될 수 있습니다.
                    <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그
                    개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
                    요구할 수 없습니다.
                    <br />⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
                    요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나
                    정당한 대리인인지를 확인합니다.
                    <br />
                    <br />{" "}
                    <Typo.s1>제 8조 (개인정보의 안전성 확보조치)</Typo.s1>
                    <br />
                    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
                    취하고 있습니다.
                    <br />
                    1. 관리적 조치 : 내부관리계획 수립·시행
                    <br />
                    2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리
                    개인정보의 암호화, 보안프로그램 설치 및 갱신
                    <br />
                    3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
                    <br />
                    <br />{" "}
                    <Typo.s1>
                        제 9조 (개인정보 자동 수집 장치의 설치·운영 및 거부에
                        관한 사항)
                    </Typo.s1>
                    <br />① 회사 이용자에게 개별적인 맞춤서비스를 제공하기 위해
                    이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
                    사용합니다.
                    <br />② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가
                    이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
                    이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                    <br />
                    가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹
                    사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
                    여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                    사용됩니다.
                    <br />
                    나. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구{">"}
                    인터넷 옵션{">"}개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을
                    거부 할 수 있습니다.
                    <br />
                    다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이
                    발생할 수 있습니다.
                    <br />
                    <br /> <Typo.s1>제 10조 (개인정보 보호책임자)</Typo.s1>
                    <br />① 회사는 개인정보 처리에 관한 업무를 총괄해서
                    책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
                    피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                    지정하고 있습니다.
                    <br />‣ 개인정보 보호책임자
                    <br />
                    성명 : 권영경
                    <br />
                    직책 : 대표
                    <br />
                    연락처 : from.anonnn@gmail.com
                    <br />② 정보주체는 회사의 서비스를 이용하시면서 발생한 모든
                    개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                    개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는
                    정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
                    <br />
                    <br /> <Typo.s1>제 11조 (개인정보 열람청구)</Typo.s1>
                    <br />
                    정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람
                    청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의
                    개인정보 열람청구가 신속하게 처리되도록 노력 하겠습니다.
                    <br />‣ 개인정보 열람청구 접수·처리 부서
                    <br />
                    부서명 : 개인정보처리부
                    <br />
                    담당자 : 권영경
                    <br />
                    연락처 : from.anonnn@gmail.com
                    <br />
                    <br /> <Typo.s1>제 12조 (권익침해 구제방법)</Typo.s1>
                    <br />① 정보주체는 개인정보침해로 인한 구제를 받기 위하여
                    개인정보분쟁조정위원회, 한국인터넷진흥원
                    개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수
                    있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는
                    아래의 기관에 문의하시기 바랍니다.
                    <br />
                    1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972
                    (www.kopico.go.kr)
                    <br />
                    2. 개인정보침해신고센터 : (국번없이) 118
                    (privacy.kisa.or.kr)
                    <br />
                    3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                    <br />
                    4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                    <br />② 회사는 정보주체의 개인정보자기결정권을 보장하고,
                    개인정보 침해로 인한 상담 및 피해 구제를 위해 노력하고
                    있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해
                    주시기 바랍니다.
                    <br />‣ 개인정보보호 관련 고객 상담 및 신고
                    <br />
                    부서명 : 개인정보처리부
                    <br />
                    담당자 : 권영경
                    <br />
                    연락처 : from.anonnn@gmail.com
                    <br />③ 「 개인정보 보호법」 제35조(개인정보의 열람),
                    제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지
                    등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분
                    또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
                    행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
                    <br />▸ 중앙행정심판위원회 : (국번없이) 110
                    (www.simpan.go.kr)
                    <br />
                    <br /> <Typo.s1>제 13조 (개인정보 처리방침의 변경)</Typo.s1>
                    <br />① 이 개인정보 처리방침은 2023. 12.09 부터 적용됩니다.
                </Typo.s4>
            </Container>
        </Column>
    );
};

const Container = styled(Column)`
    padding: 30px;
    gap: 3px;
`;
